@layer components {
  .product-card {
    @apply bg-neutral-100 border border-neutral-100 rounded-lg transition-colors cursor-pointer;
    @apply hover:border-neutral-800;

    &.product-card-list {
      @apply px-6 pt-6 pb-6 grid grid-cols-12 gap-4 items-center;

      figure {
        @apply col-span-3;

        img {
          @apply h-[7rem] w-auto mix-blend-multiply;
        }
      }

      .product-card-body {
        @apply col-span-9 relative flex flex-col w-full h-full;

        .product-card-category {
          @apply text-xl md:text-lg text-neutral-500 uppercase leading-loose md:leading-snug;
        }

        .product-card-title {
          @apply text-[1.7rem] md:text-[1.3rem] text-neutral-800 tracking-normal truncate w-9/12;
        }

        .product-card-price {
          @apply text-[2.4rem] md:text-2xl text-neutral-600 font-semibold mt-4 md:mt-2;
        }

        .product-card-actions {
          @apply absolute right-0 bottom-0;
        }
      }
    }

    &.product-card-grid {
      @apply px-4 pt-8 pb-4 flex flex-col gap-6 items-center;

      figure {
        img {
          @apply h-[14rem] w-auto mix-blend-multiply;
        }
      }

      .product-card-body {
        @apply flex flex-col w-full h-full;

        .product-card-category {
          @apply text-xl md:text-lg text-neutral-500 uppercase leading-loose md:leading-snug;
        }

        .product-card-title {
          @apply text-[1.7rem] md:text-[1.3rem] text-neutral-800 tracking-normal;
        }

        .product-card-price {
          @apply text-[2.4rem] md:text-2xl text-neutral-600 font-semibold mt-4 md:mt-2;
        }

        .product-card-actions {
          @apply mt-auto pt-8;
        }
      }
    }
  }
}