@import url("tailwind.css");
@import url("components/trash.css");
@import url("components/collapse.css");
@import url("components/dropzone.css");
@import url("components/input.css");
@import url("components/button.css");
@import url("components/product.css");

:root {
  /* Collapse bootstrap */
  --rounded-box: 0;

  /* Modal hiding sticky header */
  overflow: visible !important;
}

@layer components {
  .tooltip::before {
    @apply text-xl max-w-xl px-2 py-2;
  }

  /* Change the behaviour when the error is contained within an element. */
  div .error-container {
    height: auto;
  }

  /* Badges */
  .badge {
    @apply h-auto shadow-sm shadow-neutral-100;

    &.badge-md {
      @apply px-4 text-[1.3rem] leading-10;
    }

    &.badge-lg {
      @apply px-4 text-[1.5rem] leading-10;
    }
  }

  .badge-success {
    color: #059669;
    background-color: #d1fae5;
  }

  .badge-warning {
    color: #9c5700;
    background-color: #ffeb9c;
  }

  .badge-neutral {
    color: #64748b;
    background-color: #f1f5f9;
    border-color: #f1f5f9;
  }

  .badge-danger {
    color: #f43f5e;
    background-color: #ffe4e6;
  }

  .badge-ghost {
    @apply bg-neutral-100 text-stone-600;
  }

  .badge-active {
    @apply border-primary text-primary;
  }

  .tooltip {
    &::before {
      @apply py-1 px-3.5 text-lg tracking-wide;

      /* TODO:  font-navigation */
    }

    &::after {
      --tooltip-tail: 4px;
      --tooltip-tail-offset: 1.1rem;
    }
  }

  .dropdown-content li {
    @apply cursor-pointer transition-colors;

    > a {
      @apply hover:badge-primary;
    }
  }

  .backdrop {
    @apply top-0 left-0 right-0 bottom-0 fixed z-[1];

    background-color: rgb(0 0 0 / 30%);
    animation: modal-pop 0.2s ease-out;
  }

  body {
    overflow-y: scroll;

    @apply text-[1.4rem] md:text-[1.3rem];
  }

  .modal {
    @apply z-20;
  }

  .link {
    @apply text-primary hover:text-primary hover:brightness-[0.8] no-underline transition-all duration-200;
  }

  .form-required {
    .label-text::after {
      @apply content-['*'] text-error ml-1;
    }
  }


  label::first-letter {
    text-transform: uppercase;
  }

  .label-text-alt {
    @apply text-[1.1rem] mt-2 hidden;
  }

  .form-error {
    .label-text-alt {
      @apply block;
    }

    .input, .select, .select:focus, .textarea {
      @apply border-opacity-100 border-error !important;
    }
  }

  .form-control:invalid ~ .label-text-alt {
    @apply block;
  }

  .label-text {
    @apply text-body;

    font-size: inherit;
  }


  .table thead th {
    @apply text-lg ;
  }

  .table {
    @apply text-xl;
  }

  .table-zebra tbody tr:nth-child(even) th,
  .table-zebra tbody tr:nth-child(even) td {
    @apply bg-neutral-100;
  }

  .fa-instagram * {
    fill: url("#instagram");
  }

  .fa-facebook,
  .fa-square-facebook {
    color: #4267B2;
  }

  .fade-in {
    animation: fade-in 1s;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes impact {
    50% {
      transform: scale(1.02) translate(0, 0);
      color: hsl(var(--p));
    }
  }

  h1 {
    @apply font-nunito-sans;

    display: flex;
    align-items: center;
    font-size: 2.8rem;
    color: #545454;
    line-height: 1;
    font-weight: 800;
    margin: 3rem 0 2rem;
    text-align: left;
    text-shadow: none;
  }

  h2 {
    @apply font-nunito-sans;

    display: flex;
    align-items: center;
    font-size: 2.4rem;
    color: #545454;
    line-height: 1;
    font-weight: 800;
    text-align: left;
    text-shadow: none;

    &.wedge {
      padding-left: 1.6rem;

      &::before {
        @apply bg-primary;

        content: "";
        height: 2.8rem;
        width: 6px;
        margin-top: -0.2rem;
        margin-left: -1.6rem;
        position: absolute;
      }
    }
  }

  h3 {
    @apply font-nunito-sans;

    display: flex;
    align-items: center;
    font-size: 2.1rem;
    color: #545454;
    line-height: 1;
    font-weight: 700;
    margin: 1rem 0 0.5rem;
    text-align: left;
    text-shadow: none;
  }

  hr {
    @apply border-t-hr;
  }

  .container {
    @apply lg:max-w-[88%];
  }

  .checkbox-primary {
    --chkfg: 10 95% 95%;
  }

  .appearance-none::-webkit-outer-spin-button,
  .appearance-none::-webkit-inner-spin-button {
    @apply m-0;

    appearance: none;
  }

  .icon-primary {
    .fa-primary {
      @apply fill-primary;
    }

    .fa-secondary {
      @apply fill-neutral-800;
    }
  }

  .icon-primary-alt {
    .fa-primary {
      @apply fill-neutral-800;
    }

    .fa-secondary {
      @apply fill-primary;
    }
  }

  .invert {
    filter: invert(98%) sepia(84%) saturate(6%) hue-rotate(201deg) brightness(102%) contrast(99%);
  }

  .cogs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -15%;

    .cog-1 {
      width: 33%;
      margin: 68% 0 0 -33%;
    }

    .cog-2 {
      width: 27%;
      margin: 116% 0 0 21%;
    }

    .cog-3 {
      width: 39%;
      margin: 44% 0 0 51%;
    }

    svg {
      position: absolute;
      animation: spin 5s infinite linear;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg) translateZ(0);
      }

      to {
        transform: rotate(359deg) translateZ(0);
      }
    }
  }

  .slide-in {
    transform: translateX(-2%);
    animation: slide-in 0.5s forwards;
  }

  @keyframes slide-in {
    100% {
      transform: translateX(0%);
    }
  }

  /* Checkbox fix */
  .checkbox.checkbox-error {
    --chkbg: var(--er);
    --chkfg: var(--erc);
    --bc: var(--er)
  }
}
