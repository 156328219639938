/* trashcan */
@layer components {
  .trash-box {
    width: 50px;
    height: 50px;
    position: relative;

    /* Hover */
    &:hover .trash-top {
      /* open the lid */
      transform: translate(-50%, -15%) rotate(-20deg);
    }

    &:hover .trash-btm {
      /* rotate the bottom a little */
      transform: translate(-50%, -100%) rotate(5deg);
    }

    &:hover .trash {
      top: 50%;
      transform: translate(-34%, -50%) rotate(35deg); /* comes from bottom */
    }

    /* trash-lid */
    .trash-top {
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translate(-50%, -15%);
      width: 80%;
      height: 15%;
      border-radius: 5px;
      transform-origin: left bottom;
      transition: all 0.2s ease-in-out;

      @apply bg-error brightness-90;

      /* the top of lid */
      &::before {
        content: "";
        position: absolute;
        bottom: 95%;
        left: 50%;
        transform: translate(-50%);
        width: 25%;
        height: 90%;
        background: inherit;
        border-radius: 5px 5px 0 0;
      }
    }

    /* trash-can-bottom */
    .trash-btm {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -100%);
      width: 70%;
      height: 65%;
      border-radius: 2px;
      transform-origin: left 70%; /* transform left-side and 70% from top */
      transition: all 0.2s ease-in-out;

      @apply bg-error brightness-90;
    }

    /* trash in(behind) the can */
    .trash {
      position: absolute;
      top: 80%;
      left: 38%;
      transform: translate(-38%, -80%) rotate(35deg);
      width: 40%;
      height: 40%;
      border-radius: 2px;
      transition: all 0.2s ease-in-out;

      @apply bg-neutral-700;

      /* second trash */

      &::before {
        content: "";
        position: absolute;
        transform: translate(30%, -20%) rotate(25deg);
        width: 100%;
        height: 100%;
        border-radius: 2px;

        @apply bg-neutral-800;
      }
    }
  }
}